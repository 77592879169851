import css from "./info.module.css";
import srvd from "../../img/aboutPic.png";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
export default function AboutAuth() {
  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, "aboutAuthContent", "mainPageTexts");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) setContent(docSnap.data());
    };

    fetchContent();
  }, []);
  return (
    <div className={css.aboutWrapAll}>
      <div className={css.smallWrapAbout}>
        <div className={css.wrapTwoButton}>
          <a className={css.aboutCompanyH1One} href="#section1">
            Авторам текстів
          </a>{" "}
          <a className={css.aboutCompanyH1} href="#section2">
            Художникам
          </a>
        </div>
        <div className={css.wpaPicAbout}></div>
        <div id="section1" className={css.autorsNewLabel}>
          Авторам текстів
        </div>
        <p
          className={css.paragInWrapBig}
          dangerouslySetInnerHTML={{ __html: content.section1_intro }}
        >
          {/* Якщо у вас є історія, яка має бути розказана іншим, заради цього варто
          жити. Ми шукаємо рукопис, який прославив би Україну на весь Світ. Який
          був би цінним і через 10 років. Для нас важливо, щоб рукопис читався
          на одному подиху, але цього недостатньо. Ми дивимося на те, які зерна
          сіє рукопис в дітях, і як вони проростають. Наше завдання — створити
          необхідні дітям книжки, наша мета — зробити їх витворами мистецтва.    */}
        </p>
        <p className={css.zagalP}>Загальне звернення до Автора.</p>
        <p className={css.zagalNotNeed}>
          (Необов’язкове до прочитання. Виключно для допитливих. Всіх інших
          просимо не заморочуватись. Ми не школа і не партія — нікого не вчимо й
          нікому нічого не доводимо.)
        </p>
        <p
          className={css.zagalNotNeedDesc}
          dangerouslySetInnerHTML={{ __html: content.section1_general }}
        >
          {/* Шановні Автори, ми радіємо прагненню до творчості без винятків, тому
          можемо написати про це у звернені до всіх.  Творчість прекрасна сама
          по собі. Творчість відрізняє нас від тварин. Творчість — необхідна
          складова подальшої еволюції та майбутнього світу. Ми знаємо, чому
          краса врятує світ. Творчість корисна в першу чергу для Творця, вона
          може бути причиною його особистої еволюції. Книга в книгарні
          відрізняється від Твору Автора не тільки поліграфічною, художньою та
          редакторською обробкою. Книга — це продукт, який повинен принести
          користь публіці. Твір — це продукт, що приніс користь Автору, але цієї
          причини недостатньо, щоб він став продуктом. Твір повинен мати великий
          потенціал принести користь публіці та бути засвоєним, «перетравленим»
          більшістю читачів із цільової аудиторії, для якої він створений. Не
          кожен Твір треба тиражувати. (Далі наші «заморочки», на які ми
          орієнтуємося. Вони випливають з нашої місії. Тут можете припиняти
          читання, але якщо вам цікаво, читайте статтю «Про видавництво» (хоча
          наші піарники кажуть, що немає сенсу писати стільки тексту, бо людям
          читати про якісь там місії ніколи). Завдання видавництва «Час
          майстрів» — знайти саме той дитячий Твір, який має великий потенціал
          стати корисним продуктом не тільки для українських дітей, а й для
          дітей всього Світу. Настав час майстрів і для України. Справжній
          майстер буде цінним і для іноземних спільнот. Основними рисами
          Твору-продукту (взагалі критеріїв багато й вони змінюються), який ми
          шукаємо є: необхідність, сприяння розвитку читача (це достатні
          критерії); легке читання (на одному подиху); Твір написаний красивою
          мовою, або «дитячою мовою», мовою потреб цільової аудиторії,
          характерний Герой, робота з почуттями й емоціями читача, сила твору,
          робочий сюжет що «красиво» реалізує силу твору (це важливі, проте
          недостатні критерії). Ідея має бути наче скелет, а історія — як
          здорове, красиве тіло.  Крім того, «Час майстрів» шукає в авторському
          Творі ознаки СУЛ (Сучасної Української Літератури). Що таке «сучасна»
          легко збагнути, що таке «література» трохи складніше, але про це
          багато написано, а ось що таке «Сучасна» і при цьому «Українська» —
          питання відкрите. Будемо вдячні, якщо поділитеся своєю думкою в
          коментарях до статті блогу «Пошуки Сучасної Дитячої Літератури». */}
        </p>
        <p className={css.dogovirPd}>
          Публічна оферта щодо розгляду Творів Автора рукописів
        </p>
        <div className={css.wrapTwoOffer}>
          <p
            className={css.firstDogovir}
            dangerouslySetInnerHTML={{ __html: content.section1_desc }}
          >
            {/* Вам не треба натискати на кнопку про згоду. Підтвердженням вашої
            згоди є факт надсилання вами Твору на адресу видавництва «Час
            майстрів». 1. Видавництво «Час майстрів», не є групою критиків (а
            хто такий критик? (будемо вдячні за вашу думку, висловлену в
            коментарях під статтею блогу «Шукаємо критиків дитячої літератури»),
            ми — професійні покупці рукописів, продавці та популяризатори книг.
            Тому наша думка про Твір не є рецензією чи остаточним вироком. 2.
            Видавництво «Час майстрів» не може купити всі Твори. Якщо Автор
            отримав нашу відмову, це не значить, що його Твір поганий. Кожен
            вибирає свого Майстра, в якого вірить, або якому довіряє. Так у
            крамниці багато книжок і кожен купує свою, але це не є ознакою того,
            що інші книги погані. 3. Заздалегідь просимо вибачення за прямі
            відповіді без детальних пояснень. Знаємо, що така прямота може
            поранити душу кожного Творця, але це виробництво товару для великої
            спільноти й тут наше завдання схоже на завдання лікаря, робота йде
            активно, швидко, на результат. 4. Видавництво не має окремої людини,
            яка читає рукописи 8 годин робочого часу на день. Рукописи читають
            працівники після робочого дня. Тому об’єм прочитаних сторінок за
            день не задовольняє як видавництво, так і Авторів, що подають Твори
            на розгляд. Прийміть той факт, що пропускна здатність видавництва
            низька і розгляд проходить у невизначений термін. 5. Якщо протягом
            трьох місяців Автор не отримав відповіді, просимо повторити запит, і
            так кожні три місяці. 6. Ми пишемо короткі відповіді, тому що
            обмежені в часі. Ми не надаємо рецензій та відгуків, тільки короткі
            відповіді по суті запиту. 7. Ми не читаємо pdf, просимо надсилати
            виключно doc. 8. Видавництво залишає за собою право залишити
            звернення без відповіді, але ми прагнемо відповідати на максимальну
            кількість листів. 9. Видавництво не повертає та не рецензує
            надіслані на розгляд матеріали.Видавництво «Час майстрів» ввело в
            структуру сайту проєкт “Читання рукописів” (інтерактивне посилання
            на сторінку) для того, щоб підвищити шанси рукопису бути поміченим і
            виданим. Це прототип системи соціальної відповідальності за
            просування Творів. Ідея проста: зареєстровані читачі можуть
            прочитати 20% рукопису. Читання відбувається прямо на сайті. Щоб
            отримати доступ до подальшого читання повного рукопису, читачі
            повинні натиснути кнопку «Читати далі». Натискання цієї кнопки падає
            до скарбнички числа «Кількість зацікавлених читати далі» (загальна
            кількість читачів не рахується). Крім того, рукопис характеризується
            числом лайків, числом відгуків та їхнім змістом. Видавництво
            першочергово прочитує рукописи, йдучи від більшого числа до меншого.
             Подані твори модеруються. Не кожен Твір може отримати дозвіл на
            сайт-публікацію, лише потенційно цікаві видавництву. На цьому етапі
            видавництво позачергово розглядає анкету Твору, сам Твір на цьому
            етапі ми не читаємо. Після отримання дозволу на публікацію, Автор
            має право створити особисту сторінку Автора на сайті «Час майстрів»
            для своєї популяризації.  Це початок популяризації ще до видання
            твору. Це механізм наполовину автоматизованого включення Твору до
            редакційного плану видавництва. Цей механізм видавництво планує
            розвинути в такий, що буде допомагати допрацьовувати потенційні
            шедеври до рівня шедеврів — системи соціальної співучасті у
            написанні Твору. Ви можете допомогти своїм колегам. На сайті «Час
            майстрів» є розділ Рукописи (інтерактивне посилання після натискання
            переходиш на стор. РУКОПИСИ), де Автори надають тимчасовий доступ до
            читання своїх Творів. Твори, доступні в цьому розділі, підіймають
            свій рейтинг завдяки оцінкам і відгукам читачів. Якщо рейтинг Твору
            високий, такий Твір розглядається видавництвом до друку та промоції
            поза чергою.  Просимо вас читати рукописи колег по цеху, що чекають
            своєї черги до видання, ставити їм оцінки та писати відгуки.  Спіч,
            що продасть книгу, створену з вашого Твору. Що це таке? Уявіть собі,
            що ваша книга вже видана. Тепер ви продавець у книгарні, не автор.
            До магазину заходять покупці, у всіх обмаль часу. Це мами, на яких
            діти чекають у дитячих садочках, а вони в обідню перерву вибігли у
            книжковий за черговою книгою для дитини. Їм ні́коли слухати вас довше
            однієї хвилини. Що ви скажете за цю хвилину, щоб продати свою Книгу? */}
          </p>{" "}
          <p
            className={css.secondDogovir}
            dangerouslySetInnerHTML={{ __html: content.section1_offer }}
          >
            {/* Як подати рукопис на розгляд: 1. На розгляд приймаються не лише
            завершені твори, а й ідеї творів. 2. Тексти надсилайте в
            електронному вигляді (файл Word) на e-mail
            origin@chasmaistriv.com.ua 3. Вкажіть у листі інформацію про автора:
            контакти (бажано номер мобільного телефону), попередні публікації,
            вік, освіта тощо. 4. Надайте таку інформацію про твір: a. вік
            читацької аудиторії; b. спіч, що продасть книгу, створену з вашого
            Твору; c. короткий зміст твору; d. образ головного героя, який
            приклад він подає читачеві, його прагнення; e. яка «пружина» рухає
            сюжет? Якщо не розумієте, що за «пружина», то не треба надсилати нам
            рукопис; f. Що отримають діти в процесі читання, з чим залишаться
            після того, як закриється остання сторінка? g. Як думаєте, чи згадає
            дитина цей твір через рік і що саме залишиться в її пам’яті? h. В
            чому зовнішній конфлікт історії, в чому її внутрішній конфлікт, як
            вони вирішуються за допомогою один одного? i. Який жанр вашого
            Твору? 5. Напишіть, що спонукало вас до створення цього Твору, що ви
            прагнете донести до читачів? 6. Ми не хочемо читати загальні слова,
            що транслюють загальні цінності, особливо прикметники, на кшталт:
            якісний, прекрасний, суперовий, чудовий, цікавий. Та деякі дієслова
            на кшталт: всім моїм сподобалось, маю купу схвальних відгуків,
            розвиває фантазію, навчає дружити, робити добро і т. д. Деколи
            відхиляємо заявки з подібними словами, не дочитуючи. Ви можете
            опублікувати свій твір на сайті видавництва «Час майстрів»
            самостійно вже зараз. Перед публікацією твору на сайті обов’язково
            ознайомтесь з договором оферти про надання невиключної ліцензії на
            використання Твору. (інтерактивне посилання для переходу на стор.
            оферти)Факт публікації Твору в проєкті «Читай рукопис» є фактом
            настання охорони Твору законом про авторське право від дати
            публікації. Видавництво не передає файли Твору третім особам, але не
            гарантує і не забезпечує підвищений захист від копіювання чи
            сканування тексту. Публікація Твору в проєкті «Читай рукопис»
            базується на такій стратегії: Автор надає вільний безоплатний доступ
            до одного зі своїх Творів, тільки для читання на сайті «Час
            майстрів» терміном на рік. Завдяки цьому дозволу, читачі завжди
            мають доступ до сервісу історій для читання своїм дітям з будь-якого
            місця в будь-який час. Сайт забезпечує доступ до Твору, інформаційну
            підтримку, рейтингування рукописів. Такий ресурс забезпечує читачів
            сервісом обрання актуальної історії для особистих потреб, Автора —
            відгуками, підвищенням шансу на публікацію, просуванням як твору,
            так і особистого імені, нам це допомагає звернути увагу на Твори,
            які ще не були розглянуті видавництвом. Через рік Твір знімається з
            публікації та переходить в редакційний план видавництва за згодою
            Автора, або повертається Автору. Шанс того, що Твір буде безправно
            розповсюджений в інтернеті маленький, бо новий твір ще не є тим
            об’єктом, що підвищує трафік інтернет-ресурсам. На початковому етапі
            він є цінністю лише для читачів у рамках сервісу. Якщо при
            моніторингу інтернету Автор знайшов опубліковану Копію Твору, то на
            цей ресурс надсилаються відповідні претензії, після чого ресурс
            видаляє цей Твір. Завдяки участі у проєкті Автор підвищує свою
            впізнаваність і шанси на публікацію.  Відібранні до видання Твори
            проходять експертизи: психологічну, мовну, Клубу Читак від
            видавництва «Час майстрів». Потім твір отримує узагальнене рішення
            редактора «Час майстрів» про доцільність чи недоцільність видання.
            Кінцевим етапом для Творів, що отримали позитивні відгуки на всіх
            етапах, є надання остаточного висновку про видання або рекомендації
            щодо доопрацювання Твору для подальшого видання. */}
          </p>
        </div>
        <p className={css.redPIn}>
          Просимо поваги до нашої роботи наступним чином:
        </p>
        <p
          className={css.opuswork}
          dangerouslySetInnerHTML={{ __html: content.section2_intronew }}
        >
          {/* Оберіть свого видавця. Обирає не видавництво, спочатку обирає Автор.
          Ми сподіваємось, що перед тим, як надіслати свою пропозицію, ви
          вивчали сайт, книжки видавництва, дізнались про нашу місію, вивчили
          розділ для Авторів і надіслали Твір з урахуванням наших очікувань і
          прохань до Автора; Очікуємо, що ваше повідомлення важить менше ніж 500
          кб. Важкі файли треба надсилати через доступ на віддалений диск;
          Продемонструйте свій авторський стиль у перших реченнях вашого
          повідомлення. Якщо ви автор, то маєте вміти заінтригувати з перших
          слів; Чим коротше, тим краще; Уникайте шаблонів; Відповіді на деякі
          шаблонні запитання: Топ 5-ть критеріїв, за якими видавництво обирає
          рукописи: Захоплює і не відпускає (необхідно та недостатньо); Сильний
          меседж (необхідно та недостатньо); Герой, що залишиться з тобою по
          життю (необхідно та недостатньо); Мова як насолода (необхідно та
          недостатньо); Автор готовий працювати, щоб стати зіркою
          літератури. Наскільки заповнений редакційний портфель? Яка черга на
          розгляд рукописів? Скільки може тривати розгляд рукопису? Портфель
          практично завжди порожній; Черга практично нескінченна у порівнянні зі
          швидкістю розгляду; Рукопис розглядають до пів року. Які саме рукописи
          очікує побачити видавництво? (Прим. ред. перелічені питання дублюються
          за змістом, раджу залишити лише одне з них). Ми шукаємо історії,
          необхідні дітям для розвитку; Нон-фікшн для дошкільнят; Шукаємо
          авторів, які зможуть працювати під замовлення видавництва. Погляд
          видавництва на конкуренцію українських авторів з ліцензійними
          іноземними готовими бестселерами: Українські автори програють
          конкуренцію світовим бестселерам дитліту. Простіше купити ліцензію,
          ніж видавати українське. Порада авторам: спробуйте написати так, щоб
          продати права американцям, англійцям, німцям, французам. На вашу
          думку, які основні принципи та технології продажу рукопису? Вміння
          автора зазирати в себе і свій рукопис та відверта трансляція того, що
          ти там побачив у найкоротшій, переважно іронічній формі. Хто ясно
          розуміє, той ясно говорить. */}
        </p>
        <div id="section2" className={css.hudognukNewLabel}>
          Художникам
        </div>
        <p
          className={css.paragInWrapBig}
          dangerouslySetInnerHTML={{ __html: content.section2_intro }}
        >
          {/* Просимо вас надсилати посилання з вашим портфоліо на e-mail */}
        </p>
        <p
          className={css.postIn}
          dangerouslySetInnerHTML={{ __html: content.section2_post }}
        >
          {/* origin@chasmaistriv.com.ua */}
        </p>
        <p
          className={css.paragInWrapBig}
          dangerouslySetInnerHTML={{ __html: content.section2_general }}
        >
          {/* Очікуємо, що ваше повідомлення важить менше ніж 500 кб. Важкі файли
          треба пересилати як доступ на віддалений диск, або посилання на ваш
          ресурс. */}
        </p>
        <p className={css.zagalP}>Загальне звернення до Художників.</p>
        <p className={css.zagalNotNeed}>
          (Необов’язкове до прочитання. Виключно для допитливих. Всіх інших
          просимо не заморочуватись. Ми не школа і не партія — нікого не вчимо й
          нікому нічого не доводимо.)
        </p>
        <p
          className={css.zagalNotNeedDesc}
          dangerouslySetInnerHTML={{ __html: content.section2_desc }}
        >
          {/* Наш девіз — настав час майстрів. Наше завдання — знайти майстра і
          познайомити з ним дітей через книгу. Якщо ми повірили у Майстра,
          вибрали його, то ми йому довіряємо. Для нас важлива його вільна
          творчість без всіляких обмежень. Єдине, в чому нам не вдається не
          обмежувати творця, це в бюджеті та в тексті, до якого створюються
          зображення. Вас цікавить об’єм ілюстрацій? Іноді ми його даємо, а
          іноді ні, бо читаючи книгу, ви ілюструєте саме ті моменти, які вас
          надихнули! Свобода і довіра — основа прояву істинного творіння. Якщо
          ми починаємо працювати, то спочатку обговорюємо ціну. Другий етап:
          затверджуємо ескіз, який не оплачується Третій етап — підписуємо
          договір. Далі робота, яка теж має етапи.  Наприкінці дякуємо
          художникам за роботу. */}
        </p>
      </div>
    </div>
  );
}
