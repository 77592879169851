// const transliterate = (text) => {
//   const transliterationMap = {
//     А: "A",
//     Б: "B",
//     В: "V",
//     Г: "H",
//     Ґ: "G",
//     Д: "D",
//     Е: "E",
//     Є: "Ye",
//     Ж: "Zh",
//     З: "Z",
//     И: "Y",
//     І: "I",
//     Ї: "Yi",
//     Й: "Y",
//     К: "K",
//     Л: "L",
//     М: "M",
//     Н: "N",
//     О: "O",
//     П: "P",
//     Р: "R",
//     С: "S",
//     Т: "T",
//     У: "U",
//     Ф: "F",
//     Х: "Kh",
//     Ц: "Ts",
//     Ч: "Ch",
//     Ш: "Sh",
//     Щ: "Shch",
//     Ю: "Yu",
//     Я: "Ya",
//     а: "a",
//     б: "b",
//     в: "v",
//     г: "h",
//     ґ: "g",
//     д: "d",
//     е: "e",
//     є: "ye",
//     ж: "zh",
//     з: "z",
//     и: "y",
//     і: "i",
//     ї: "yi",
//     й: "y",
//     к: "k",
//     л: "l",
//     м: "m",
//     н: "n",
//     о: "o",
//     п: "p",
//     р: "r",
//     с: "s",
//     т: "t",
//     у: "u",
//     ф: "f",
//     х: "kh",
//     ц: "ts",
//     ч: "ch",
//     ш: "sh",
//     щ: "shch",
//     ю: "yu",
//     я: "ya",
//     ь: "",
//     "’": "",
//     ʼ: "",
//   };

//   return text
//     .split("")
//     .map((char) => transliterationMap[char] || char)
//     .join("")
//     .replace(/\s+/g, "_");
// };
// export default transliterate;
const transliterate = (text) => {
  const transliterationMap = {
    А: "A",
    Б: "B",
    В: "V",
    Г: "H",
    Ґ: "G",
    Д: "D",
    Е: "E",
    Є: "Ye",
    Ж: "Zh",
    З: "Z",
    И: "Y",
    І: "I",
    Ї: "Yi",
    Й: "Y",
    К: "K",
    Л: "L",
    М: "M",
    Н: "N",
    О: "O",
    П: "P",
    Р: "R",
    С: "S",
    Т: "T",
    У: "U",
    Ф: "F",
    Х: "Kh",
    Ц: "Ts",
    Ч: "Ch",
    Ш: "Sh",
    Щ: "Shch",
    Ю: "Yu",
    Я: "Ya",
    а: "a",
    б: "b",
    в: "v",
    г: "h",
    ґ: "g",
    д: "d",
    е: "e",
    є: "ye",
    ж: "zh",
    з: "z",
    и: "y",
    і: "i",
    ї: "yi",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "kh",
    ц: "ts",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ю: "yu",
    я: "ya",
    ь: "",
    "’": "",
    ʼ: "",
  };

  return text
    .split("")
    .map((char) => transliterationMap[char] || char) // Транслітерація символів
    .join("")
    .replace(/\s+/g, "_") // Заміна пробілів на "_"
    .toLowerCase() // Перетворення в нижній регістр
    .split(/[?!&]/)[0]; // Обрізання тексту за символами "?", "!", "&"
};

export default transliterate;
