import css from "./info.module.css";
import srvd from "../../img/aboutPic.png";

export default function Delivery() {
  return (
    <div className={css.aboutWrapAll}>
      <div className={css.smallWrapAbout}>
        <div className={css.wrapTwoButton}>
          <a className={css.aboutCompanyH1One} href="#section1">
            Доставка
          </a>{" "}
          <a className={css.aboutCompanyH1One} href="#section1">
            Оплата
          </a>
          <a className={css.aboutCompanyH1One} href="#section2">
            Самовивіз
          </a>
          <a className={css.aboutCompanyH1Two} href="#section3">
            Де пощупати наші книги
          </a>{" "}
          <a className={css.aboutCompanyH1Thre} href="#section4">
            Книги ЧМ на інших ресурсах
          </a>{" "}
        </div>

        <div id="section1" className={css.buttonGrenDostavka}>
          Доставка
        </div>
        <div className={css.wrapTwoOffer}>
          {" "}
          <p className={css.firstDogovir}>
            Ціни, зазначені на сайті, є остаточними й не вимагають доплат при
            стандартних умовах постачання. Усі податки входять у вартість
            товару. Так само пакування замовлень повністю на видавництві - ви не
            платите при доставці нічого додатково! Доставка по Україні: Книжки,
            замовлені в інтернет-магазині, надсилаємо виключно компанією "Нова
            пошта" до ваших найближчих віділень чи поштоматів із складу у
            Вінницькій обл. Можлива адресна доставка кур'єром Нової пошти.
          </p>
          <p className={css.secondDogovir}>
            Доставку книжок оплачує замовник за цінами компанії-перевізника. Для
            замовлень вартістю від 1000 грн доставка є безкоштовною. Сума
            мінімального замовлення в інтернет-магазині – 100 грн.  Вартість
            доставки залежить від ваги книжок і відстані від Києва до вашого
            міста. Ми не можемо відповідати за якість роботи поштових
            перевізників, але у разі проблем із доставкою намагаємося надавати
            замовникові всіляку підтримку.
          </p>
        </div>
        <div className={css.buttonGrenDostavka}>Оплата</div>
        <div className={css.wrapTwoOffer}>
          {" "}
          <p className={css.firstDogovir}>
            На жаль, посилки, відправлені післяплатою, замовники не завжди
            забирають із пошти, й тоді інтернет-магазинам доводиться оплачувати
            всі поштові послуги. Для нас це часто вартість надісланої книжки.
            Тому ми працюємо суворо за ПЕРЕДОПЛАТОЮ на розрахункові рахунки
            Приватбанку. Реквізити надсилаємо після оформлення замовлення.
            Повернення товару Відповідно до Постанови Кабінету Міністрів України
            від 19 березня 1994 р. № 172 "Про реалізацію окремих положень Закону
            України "Про захист прав споживачів" друковані видання належної
            якості не підлягають обміну, поверненню. У випадку виявлення
            поліграфічного браку книжкової продукції Продавець, за бажанням
            Покупця, здійснює обмін товару на товар належної якості, або
            здійснює повернення коштів у розмірі вартості повернутої продукції.
            Поліграфічний брак – це брак, отриманий внаслідок недотримання
            технології виробництва, що призводить до перекручування або втрати
            інформації: розмазування фарби, непродруковування фарби, нечіткий
            друк, склеєні сторінки, нерівне обрізання, перевернуті аркуші,
            відсутність аркушів, або їх повторення, невідповідність назви книжки
            на обкладинці її змісту, тощо).
          </p>
          <p className={css.secondDogovir}>
            Умови замінювання бракованої продукції Видавництво "Час майстрів"
            гарантує замінювання бракованої продуції на продукцію, що відповідає
            заявленій якості. Для цього необхідно: 1) відкрити посилку у
            поштовому відділенні; 2) сфотографувати брак так, щоб було видно
            книжку повністю та інтер’єр поштового відділення; 3) сфотографувати
            сам брак зблизька, великим планом; 4) сфотографувати паковання та
            пошкодження паковання, щоб встановити причини браку: чи не пошкодив
            книжку перевізник у процесі доставки; 5) якщо пошкодження книжки
            виникли з вини перевізника, запросити працівника пошти, щоб він це
            зафіксував; 6) того ж дня, коли посилку було отримано у поштовому
            відділенні, повідомити видавництво про браковану продукцію. 7)
            повідомлення про брак надсилайте на електронну адресу
            lesia.chasmaistriv@gmail.com Увага! Заяви про отримання бракованої
            продукції видавництво приймає лише в день її отримання і лише з
            фотографіями на тлі поштового відділення.
          </p>
        </div>
        <div id="section2" className={css.buttonGrenDostavka}>
          Самовивіз
        </div>
        <p className={css.paragInWrapBig}>
          На сьогоднішній день можливість самовивозу - відсутня. Після перемоги
          ми будемо раді знову бачити вас у нашому шоу-румі. ПОТРЕБУЄ ПЕРЕВІРКИ
          ЗОРЯНИ! Любі читачі, нас часто запитують, де можна купити наші книжки,
          окрім інтернет-магазину та книжкових виставок. Поступово ми будемо
          викладати список магазинів у різних містах, які співпрацюють
          безпосередньо з нашим видавництвом. м. Вишнівець
        </p>
        <div id="section3" className={css.buttonGrenWherek}>
          Де пощупати наші книги
        </div>
        <p className={css.mainKuiv}>м. Київ</p>
        <div className={css.wrapTwoOffer}></div>
        <p id="section4" className={css.paragInWrapBig}>
          Книги Час майстрів на інших ресурсах
          <br />
          Польща ALLEGRO
          <br />
          Barnabus Books https://allegro.pl/uzytkownik/Barnabus_Books
          <br />
          Книги Час майстрів на "ПРОМі" https://chasmaistriv.in.ua/ua/
          <br />
        </p>
        <div className={css.probil}></div>
      </div>
    </div>
  );
}
